import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import HOC from "../../img/hoc.png";
import MusicApp from "../../img/musicapp.png";
import { themeContext } from "../../Context";
import { Link } from "react-scroll";
import data from '../../utils/slider.json'
import { sliderSettings } from '../../utils/common';
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='maindiv'>
      <div className="portfolio" id="portfolio">
        {/* heading */}
        <span style={{ color: darkMode ? 'white' : '' }}>Recent Projects</span>
        <span>Portfolio</span>
      </div>
      <section className='r-wrapper '>
        <div className='paddings r-container '>

          {/* 
          <Swiper {...sliderSettings} >

            {
              data.map((card, i) => (

                <SwiperSlide key={i}> */}

          <div className='flexColCenter r-card'>
            <img src="./hompyhome.png" alt='"home' />
            <a href="https://real-estate-website-by-sahil.pages.dev/" target="_blank" rel="noopener noreferrer">
              <button className="button n-button project-button">View live</button>
            </a>
            <Link to="/source-code">
              <button className="button n-button project-button">Source Code</button>
            </Link>
            {/* <span className='secondaryText r-price'>
                                        <span style={{ color: "orange" }}>$</span>
                                        <span>{card.price}</span>
                                    </span>

                                    <span className='primaryText'>{card.name}</span>
                                    <span className='secondaryText'>{card.detail}</span> */}
          </div>
          {/* </SwiperSlide>
              ))
            }
          </Swiper> */}


          {/* digital notice board */}
          <div className='flexColCenter r-card'>
            <img src="./digitalnoticeboard.png" alt='"home' />
            {/* <a href="" target="_blank" rel="noopener noreferrer"> */}
            <button className="button n-button project-button">View live</button>
            {/* </a> */}
            <a href="https://github.com/sahilkhan89/Digital-Notice-Board" target="_blank" rel="noopener noreferrer">

              <button className="button n-button project-button">Source Code</button>
            </a>

          </div>


          {/* job portal */}
          <div className='flexColCenter r-card'>
            <img src="./jobportal.png" alt='"home' />
            {/* <a href="https://github.com/sahilkhan89/SingajiRecruitmentPortal" target="_blank" rel="noopener noreferrer"> */}
              <button className="button n-button project-button">View live</button>
            {/* </a> */}
            <a href="https://github.com/sahilkhan89/SingajiRecruitmentPortal" target="_blank" rel="noopener noreferrer">
              <button className="button n-button project-button">Source Code</button>
              </a>

          </div>

          {/* react crud app */}
          <div className='flexColCenter r-card'>
            <img src="./crudhome.png" alt='"home' />
            <a href="https://react-redux-cart-by-sahil.pages.dev/" target="_blank" rel="noopener noreferrer">
              <button className="button n-button project-button">View live</button>
            </a>
            <a href="https://react-redux-cart-by-sahil.pages.dev/" target="_blank" rel="noopener noreferrer">
              <button className="button n-button project-button">Source Code</button>
            </a>

          </div>
          {/* react crud app */}
          {/* <div className='flexColCenter r-card'>
            <img src="./crudhome.png" alt='"home' />
            <a href="https://real-estate-website-by-sahil.pages.dev/" target="_blank" rel="noopener noreferrer">
              <button className="button n-button project-button">View live</button>
            </a>
            <Link to="/source-code">
              <button className="button n-button project-button">Source Code</button>
            </Link>
          </div> */}


        </div>


      </section>

    </div>
  );
};

export default Portfolio;
